<div class="m-0 p-0 mb-3 pb-2">
  <div class="container">
    <gk-list-header
      [title]="listConfigs.generalConfig.title | translate"
    ></gk-list-header>
  </div>
</div>

<div class="container py-2 dynamic-list-container">
  @if (!listConfigs.generalConfig.hideFilterCreator) {
    <gk-filter-creator
      [columnConfigs]="listConfigs.columnConfigs"
      (changeFilters)="handleFilterChange($event)"
    ></gk-filter-creator>
  }

  <gk-column-manager
    [columnConfigs]="listConfigs.columnConfigs"
    [messagesListConfig]="listConfigs.messagesListConfig"
  ></gk-column-manager>

  <div class="table-responsive" [hidden]="!shouldShowListAndPagination()">
    <ul class="w-100 list-inline d-flex flex-nowrap dynamic-list__header">
      @for (columnConfig of listConfigs.columnConfigs; track $index) {
        <li
          scope="col"
          class="list-inline-item cell m-0 p-0 fw-bold"
          [ngClass]="columnConfig.cellSize"
          [hidden]="!columnConfig.show"
        >
          <button
            class="btn btn-color-from-db w-100 d-flex justify-content-between px-1"
            [ngClass]="{ 'not-hoverable': !columnConfig.sortable }"
            (click)="handleColumnLabelClick(columnConfig)"
          >
            <span class="px-1 mw-100 d-inline-block text-truncate">
              <gk-translated-label
                [configLabel]="columnConfig.controlConfig.label"
              ></gk-translated-label>
            </span>
            <span class="ps-1">
              <i
                class="fa"
                [ngClass]="{
                  'fa-sort-up': columnConfig.currentSortType === SortType.Asc,
                  'fa-sort-down': columnConfig.currentSortType === SortType.Desc
                }"
              ></i>
            </span>
          </button>
        </li>
      }
    </ul>

    @if (requests !== responses) {
      <div class="d-flex justify-content-center my-3">
        <div class="spinner-border" role="status">
          <span class="visually-hidden">{{ 'LOADING' | translate }}</span>
        </div>
      </div>
    }

    @if (requests === responses) {
      @switch (listConfigs.listDataConfig.listType) {
        @case (listTypeEnum.Accordion) {
          <div
            ngbAccordion
            [closeOthers]="true"
            #acc="ngbAccordion"
            (shown)="onShow($event)"
          >
            @for (record of listData; track i; let i = $index) {
              <div
                ngbAccordionItem
                [ngbAccordionItem]="record.Uuid || 'accordion-item-id' + i"
              >
                <div ngbAccordionHeader>
                  <button ngbAccordionButton class="p-1">
                    <ng-container
                      [ngTemplateOutlet]="listRowBody"
                      [ngTemplateOutletContext]="{
                        record: record
                      }"
                    ></ng-container>
                  </button>
                </div>
                <div ngbAccordionCollapse>
                  <div ngbAccordionBody>
                    <ng-template>
                      <gk-dynamic-accordion-body
                        [listDataConfig]="listConfigs.listDataConfig"
                        [data]="record"
                        (dispatchMapAction)="dispatchMapAction.emit($event)"
                      ></gk-dynamic-accordion-body
                    ></ng-template>
                  </div>
                </div>
              </div>
            }
          </div>
        }
        @case (listTypeEnum.ListGroup) {
          <ul class="list-group">
            @for (record of listData; track $index) {
              <li>
                <ng-container
                  [ngTemplateOutlet]="listRowBody"
                  [ngTemplateOutletContext]="{
                    record: record
                  }"
                ></ng-container>
              </li>
            }
          </ul>
        }
      }
    }
  </div>

  @if (listData?.length) {
    <div class="pagination-refresh-container">
      @if (
        listConfigs.generalConfig.showPagination &&
        shouldShowListAndPagination()
      ) {
        <ngb-pagination
          class="d-flex justify-content-left pt-2 pb-1 pe-1 pull-left"
          [collectionSize]="collectionSize"
          [maxSize]="5"
          [(page)]="currentPage"
          attr.aria-label="{{ 'GK.DYNAMIC_LIST.PAGINATION' | translate }}"
          (pageChange)="getListDataPage()"
        ></ngb-pagination>
      }
      <button
        type="button"
        class="btn fa fa-refresh btn-refresh"
        [ngClass]="getRefreshButtonClass()"
        (click)="refreshDynamicList()"
        attr.aria-label="{{ 'GK.DYNAMIC_LIST.REFRESH_LIST' | translate }}"
        title="{{ 'GK.DYNAMIC_LIST.REFRESH_LIST' | translate }}"
      ></button>
    </div>
  }
</div>
<ng-template #listRowBody let-record="record">
  <ul class="w-100 list-inline list d-flex flex-nowrap m-0 p-0">
    @for (columnConfig of listConfigs.columnConfigs; track columnConfig) {
      <li
        class="list-inline-item list-element m-0 pe-1 cell"
        [hidden]="!columnConfig.show"
        [ngClass]="columnConfig.cellSize"
      >
        <div>
          @switch (columnConfig.controlConfig.valueType) {
            @case (ValueType.Bool) {
              <span class="px-1 mw-100 d-inline-block text-truncate">
                {{
                  getValueForType(record, columnConfig)
                    ? ('GK.DYNAMIC_LIST.YES' | translate)
                    : ('GK.DYNAMIC_LIST.NO' | translate)
                }}
              </span>
            }
            @case (ValueType.Text) {
              <span class="px-1 mw-100 d-inline-block text-truncate">
                {{ getValueForType(record, columnConfig) }}
              </span>
            }
            @case (ValueType.Dict) {
              <span class="px-1 mw-100 d-inline-block text-truncate">
                {{ getValueForType(record, columnConfig) }}
              </span>
            }
            @case (ValueType.AppActionLink) {
              <gk-control-app-action-link
                [data]="record"
                [controlConfig]="columnConfig.controlConfig"
                [mapState]="mapState"
                (dispatchMapAction)="dispatchMapAction.emit($event)"
              ></gk-control-app-action-link>
            }
            @default {
              <span class="px-1 mw-100 d-inline-block text-truncate">
                {{ getValueForType(record, columnConfig) }}
              </span>
            }
          }
        </div>
      </li>
    }
  </ul>
</ng-template>
